<template>
    <section class="team">
        <div class="section-header">
            <h1 v-html="title"></h1>
        </div>
        <div class="main-members-wrapper">
            <div class="members-wrapper">
                <span class="subtitle" v-html="subTitle"></span>
                <person-avatar :photoUrl="artDirector.photoUrl"
                               :photoWidth="artDirector.photoWidth"
                               :photoHeight="artDirector.photoHeight"
                               :position="artDirector.position"
                               :name="artDirector.name">
                </person-avatar>
                <div v-for="(member, index) in mainMembers" :key="index" class="member">
                    <span class="name" v-html="member.name"></span>
                    <span class="position" v-html="member.position"></span>
                </div>
            </div>
            <div class="art-director">
                <person-avatar :photoUrl="artDirector.photoUrl"
                               :photoWidth="artDirector.photoWidth"
                               :photoHeight="artDirector.photoHeight"
                               :position="artDirector.position"
                               :name="artDirector.name">
                </person-avatar>
            </div>
        </div>
<!--        <div class="rest-members-wrapper"> -->
<!--            <!-- <img class="line" src="/images/team-line.svg"> -->
<!--
<!--            <h3> -->
<!--                <a href='http://futurehistory.ru' target='_blank'> -->
<!--                    <span v-html="membersTitle"></span><br> -->
<!--                    <span v-html="membersTitle2" style="border-bottom: 2px solid white;"></span> -->
<!--                </a> -->
<!--            </h3> -->
<!--
<!--            <div class="columns"> -->
<!--            <div> -->
<!--                <div v-for="(member, index) in members.filter((v,i) => i <= 4)" :key="index" class="member"> -->
<!--                    <span class="name" v-html="member.name"></span> -->
<!--                    <span class="position" v-html="member.position"></span> -->
<!--                </div> -->
<!--            </div> -->
<!--            <div> -->
<!--                <div v-for="(member, index) in members.filter((v,i) => i > 4)" :key="index" class="member"> -->
<!--                    <span class="name" v-html="member.name"></span> -->
<!--                    <span class="position" v-html="member.position"></span> -->
<!--                </div> -->
<!--            </div> -->
<!--            </div> -->
<!--        </div> -->
    </section>
</template>

<script>
    import PersonAvatar from '~/components/person-avatar/person-avatar.vue';


    export default {
        name: "team",
        components: {
            PersonAvatar
        },
        data() {
            let basePath = 'components.team';
            let title = this.getLocalized(basePath, 'title');
            let subTitle = this.getLocalized(basePath, 'subTitle');
            let membersTitle = this.getLocalized(basePath, 'membersTitle');
            let membersTitle2 = this.getLocalized(basePath, 'membersTitle2');
            let mainMembers = this.getLocalizedCollection(basePath, 'mainMembers');
            let artDirectors = this.getLocalizedCollection(basePath, 'artDirectors');
            let members = this.getLocalizedCollection(basePath, 'members');

            return {
                title,
                subTitle,
                membersTitle,
                membersTitle2,
                mainMembers,
                artDirector: artDirectors[0],
                members
            }
        }
    }
</script>

<style lang="scss">
    .team {
        margin-top: 132px !important;
        position: relative;

        @include section-header-line (-10px, -60%, 0, -32.5%, 0, -44%, 2px, -44%, 127.2vw, 14.66vw);
        @include section-header-landmark(-75px, 870px, -85px, 860px, -95px, 430px, -8vw, 66vw);

        .member {
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            margin-bottom: 28px;

            span {
                display: block;
            }

            .name {
                font-size: $desktop-font-size-2;
                font-weight: 500;
                font-style: italic;
                text-transform: uppercase;
                margin-bottom: 4px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    font-size: 1.83vw;//3.25vw;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: $phone-font-size-2;
                }
            }

            .position {
                font-size: $desktop-font-size-1;
                text-transform: lowercase;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    font-size: 1.46vw;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: $phone-font-size-1;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-bottom: 6.25vw;
            }
        }

        .main-members-wrapper {
            margin-top: 57px;
            display: flex;

            .members-wrapper {
                width: 443px;
                margin-right: 194px;

                .subtitle {
                    display: block;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    font-size: $desktop-font-size-1;
                    margin-bottom: 20px;
                    position: relative;
                    z-index: 1;

                    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                        font-size: 3.25vw;
                    }

                    @media all and (max-width: $screen-phone-max-width) {
                        font-size: $phone-font-size-2;
                        line-height: 120%;
                        width: 60%;
                        margin-bottom: 18vw;
                    }
                }

                .person-avatar {
                    display: none;

                    @media all and (max-width: $screen-phone-max-width) {
                        display: block;
                        margin: 14px auto 44.25vw auto;

                    }
                }

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    margin-right: -1.5vw;
                    width: 57.29vw;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    margin-right: unset;
                    width: unset;
                }
            }

            .art-director {
                position: relative;

                .person-avatar {
                    position: absolute;
                    top: -20px;
                    left: -42px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    display: none;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                flex-direction: column;
                width: 86.25vw;
            }
        }

        .rest-members-wrapper {
            position: relative;

            .line {
                position: absolute;
                top: -37px;
                left: -60px;
                overflow: hidden;

                //@media all and (max-width: $screen-phone-max-width) {
                //    display: none;
                //}
            }

            h3 {
                margin-top: 50px;
                margin-bottom: 43px;
                font-size: $desktop-font-size-5;
                font-weight: normal;
                text-transform: uppercase;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: normal;
                position: relative;

                a {

                    text-decoration: none !important;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: $phone-font-size-3;
                    line-height: 120%;
                    width: 100%;
                    margin-top: 12vw;
                    margin-bottom: 12vw;
                }
            }

            .columns {
                display: flex;

                >div:nth-of-type(1) {
                    margin-right: 244px;

                    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                        margin-right: 13.02vw;
                    }

                    @media all and (max-width: $screen-phone-max-width) {
                        margin-right: unset;
                    }
                }

                @media all and (max-width: $screen-phone-max-width) {
                    flex-direction: column;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 86.25vw;
            }
        }


        @media all and (max-width: $screen-phone-max-width) {
            margin-top: 115px !important;
        }
    }
</style>
