<template>
    <div class="poster">
        <a :href="theLink" target="_blank">
            <img class="posterPic" v-bind:src="this.posterUrl">
        </a>
        <div class="buyButtonWrapper" v-if="price == 0">
            <div class="buyButton buyButton_free" @click="redirectToApp">
                <div style="flex-grow: 3; text-align: center;">Бесплатно в приложении</div>
            </div>
            <div class="buyButtonShadow"></div>   
        </div>
        <div class="buyButtonWrapper" v-else-if="spectacleCode != undefined">
            <div class="buyButton" @click="openYandexWidget(spectacleCode)">
                <div style="flex-grow: 3; text-align: center; margin-left: 16px;">Купить билет</div>
                <div style="margin-right: 10px; padding-right: 10px; border-right: 2px dashed white; height: 100%;"></div>
                <div style="flex-grow: 2; margin-right: 16px; text-align: center;">{{price}} ₽</div>    
            </div>
            <div class="buyButtonShadow"></div>   
        </div>
        <div class="buyButtonWrapper" v-else="">
            <div class="buyButton" @click="redirectToApp">
                <div style="flex-grow: 3; text-align: center;">В приложении</div>
            </div>
            <div class="buyButtonShadow"></div>   
        </div>
        <h1 v-html="title"></h1>
        <div class="info-block">
            <p class="written-by" v-for="item in writtenBy">
                <span v-html="item[0]"></span><br/>
                <span class="italics" v-html="item[1]"></span>
            </p>
            <p class="description" v-html="description"></p>
            <p class="x-rated" v-html="ageRated"></p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "poster",
        props: ['posterUrl', 'posterMobileUrl', 'posterMobileHeight',
            'title', 'isStarted', 'writtenBy', 'description',
            'ageRated', 'opening', 'theLink', 'spectacleCode', 'price'],
        computed: {
        },
        methods: {
            openYandexWidget: function(spectacleCode) {
              // alert("YA");
              // ticketsteam.run(spectacleCode, 'https://api.tickets.yandex.net/widget');return false;
              window.location.href=spectacleCode
            },

            redirectToApp: function() {
              window.location.href='http://onelink.to/q9bt5e'
            }
        }
    }
</script>

<style lang="scss">
    .poster {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        background-repeat: no-repeat;
        flex-basis: 522px;
        background-size: 500px 450px;
        //background-position: top right;
        margin-bottom: 38px;

        @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
            flex-basis: 400px;
        }

        .posterPic {
            width: 100%;
            margin-bottom: -36px;
            position: relative;
            top: 10px;
        }

        h1 {
            position: absolute;
            top: 0;
            left: 0;
            font-size: $desktop-font-size-5;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;
            line-height: 1.1;
            letter-spacing: normal;

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                font-size: 35px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-5;
                line-height: 120%;
            }
        }

        .header-started-block {
            position: absolute;
            top: 86px;
            left: 0;
            width: 428px;
            height: 144px;
            transform: rotate(4deg);
            background-color: #d800d4;
        }

        .buyButtonWrapper {
            position: relative;

            @media all and (max-width: $screen-phone-max-width) {
                margin: 24px 0;
            }
        }

        .buyButton {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            width: 300px;
            background: linear-gradient(to right, #00c5f1 25%, #d800d4 80%);
            color: white;
            font-size: 20px;
            border-radius: 36px;
            z-index: 99;
            margin: 0 0 30px 0;
            cursor: pointer;

            @media all and (max-width: $screen-phone-max-width) {
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
            }

            &_free {
                background: #00c5f1;
            }
        }

        .buyButtonShadow {
            height: 60px;
            width: 260px;
            background: linear-gradient(to right, #00c5f1 25%, #d800d4 80%);
            filter: blur(20px);
            position: absolute;
            top: 20px;
            left: 20px;
            // left: 50%;
            // transform: translateX(-50%);
            z-index: 98;
            opacity: 0.7;

            @media all and (max-width: $screen-phone-max-width) {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .info-block {
            // margin-top: 376px;

            p {
                font-size: $desktop-font-size-1;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;

                @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                    font-size: 18px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: $phone-font-size-1;
                }
            }

            .written-by {
                position: relative;

                color: #00c5f1;

                .italics {
                    font-style: italic;
                }
            }

            .description {
                margin-top: 10px;
                width: 400px;

                @media all and (max-width: $screen-phone-max-width) {
                    width: unset;
                    position: relative;
                }
            }

            .x-rated {
                margin-top: 8px;
                font-weight: 900;
                color: #5e0f6f;
            }

            @media all and (max-width: $screen-phone-max-width) {
                // margin-top: 80vw;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
           margin-bottom: 61px;
        }

        @media all and (min-width: 375px) and (max-width: $screen-phone-max-width) {
            flex-basis: 86.66vw;
            background-size: 100%;
            background-position: unset;
            margin-bottom: unset;
            padding-bottom: 100px;
        }

        @media all and (max-width: $screen-phone-middle-width) {
            flex-basis: 86.56vw;
            background-size: 100%;
            background-position: unset;
            margin-bottom: unset;
            padding-bottom: 100px;
        }
    }
</style>

