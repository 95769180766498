<template>
    <section class="footer">
        <p>Фото: Ольга Павлова, Владимир Яроцкий, Евгения Филатова, Елизавета Боровикова, Егор Васильев, Станислав Миронов, Егор Заика, Петр Минаков, TASS Archive / Diomedia, ТАСС, Ольга Тупоногова-Волкова, Алексей Колпаков, Иван Куринной, Ярославна Чернова, Галина Фесенко, Надежда Александрова, Никита Столбоушкин</p></section>
</template>

<script>
    export default {
        name: "footer-component"
    }
</script>

<style lang="scss">
    .footer {
        p {
            height: 95px;
            font-size: 10px;
            line-height: 1.3;
            max-width: 800px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                // padding-left: 56px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 71vw;
                font-size: 2.4vw;
            }
        }
    }
</style>