<template>
    <div class="person-avatar">
<!--        <img class="light" src="/images/avatar-light.png"> -->
        <img class="photo" :src="photoUrl">
        <span class="name" v-html="name"></span>
        <span class="position" v-if="position" v-html="position"></span>
    </div>
</template>

<script>
    export default {
        name: "person-avatar",
        props: ['name', 'position', 'photoUrl', 'photoWidth', 'photoHeight'],
    }
</script>

<style lang="scss">
    .person-avatar {
        position: relative;
        width: 200px;

        .photo {
            position: relative;
            width: 100%;
        }

        .light {
            position: absolute;
            top: -54px;
            left: -68px;

            @media all and (max-width: $screen-phone-max-width) {
                width: 76vw;
            }
        }

        .name {
            font-size: $desktop-font-size-2;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            font-style: italic;
            text-transform: uppercase;
            line-height: 1;
            letter-spacing: normal;
            position: absolute;
            bottom: -22px;
            left: 0;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-2;
            }
        }

        .position {
            font-size: $desktop-font-size-1;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            text-transform: lowercase;
            line-height: 1;
            letter-spacing: normal;
            position: absolute;
            bottom: -75px;
            left: 0;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-1;
            }
        }
    }
</style>