<template>
    <article class="container" v-resize="onResize">
        <div class="loader"></div>
        <header-content></header-content>
        <section class="main-content">
            <instructions></instructions>
            <posters></posters>
            <artists></artists>
            <team></team>    
            <partners></partners>
            <contacts></contacts>
            <footer-content></footer-content>
        </section>
    </article>
</template>
<script>
    import HeaderContent from '~/components/header/header.vue';
    import Instructions from '~/components/instructions/instructions.vue';
    import Posters from '~/components/posters/posters.vue';
    import Team from '~/components/team/team.vue';
    import Artists from '~/components/artists/artists.vue';
    import Partners from '~/components/partners/partners.vue';
    import Contacts from '~/components/contacts/contacts.vue'
    import FooterContent from '~/components/footer/footer.vue';
    import $ from 'jquery';

    export default {
        name: 'Index',
        layout: 'default',
        components: {
            HeaderContent,
            Instructions,
            Posters,
            Team,
            Artists,
            Partners,
            Contacts,
            FooterContent
        },
        head() {
            return {
                title: this.$t('meta.title'),
                meta: [{ hid: 'description', name: 'description', content: this.$t('meta.description') }],
            }
        },
        data() {
            return {
                isChrome: false
            }
        },
        computed: {
        },
        mounted() {
            this.$nextTick(() => {
                this.$nuxt.$loading.start();
                setTimeout(() => {
                    let loader = $('.loader');
                    loader.css('opacity', 0);
                    loader.css('display', 'none');
                    this.$nuxt.$loading.finish();
                    this.onResize();
                }, 10);
            });
        }
    }
</script>
<style lang="scss">
    .container {
        width: 100vw;
        background: $background-color;
        overflow-x: hidden;

        .loader {
            background: $background-color;
            position: fixed;
            opacity: 1;
            transition: opacity 1s ease;
            z-index: 10;
            width: 100vw;
            height: 100vh;
        }

        a {
            // text-decoration: none;
            color: white;

            // &:hover {
            //     text-decoration: underline;
            // }
        }

        .main-content {
            // background: url("/images/background.svg"), $background-color;
            background: $background-color;
            background-attachment: fixed;

            color: white;

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: -34vw;
            }

            section {
                width: 1111px;
                margin: 0 auto;

                @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                    width: 950px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                }

                &.wide {
                    width: 100vw;
                }

                @include content-block-medias;
            }
        }
    }
</style>

