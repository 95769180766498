<template>
    <header>
        
        <div class="img header-logo">
            <video class="onlyDesktop" src="/images/header.mp4" autoplay muted loop playsinline></video>
            <video class="onlyMobile" src="/images/header_mob.mp4" autoplay muted loop playsinline></video>          
        </div>
        <div class="gradient"></div>
        <div class="darkener darkener-top onlyDesktop" style="background-image: url('/images/video_darkener_top.png')"></div>
        <div class="darkener darkener-top onlyMobile" style="background-image: url('/images/video_darkener_top_mob.png')"></div>
        <div class="darkener darkener-bottom onlyDesktop" style="background-image: url('/images/video_darkener_bottom.png')"></div>
        <div class="darkener darkener-bottom onlyMobile" style="background-image: url('/images/video_darkener_bottom_mob.png')"></div>
        <div style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 5;">
            <div class="logo-with-text">
                <div class="logo"></div>
                <div class="text">МОБИЛЬНЫЙ ХУДОЖЕСТВЕННЫЙ ТЕАТР</div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "header-component"
    }
</script>

<style lang="scss">
    header {
        position: relative;
        width: 100%;
        height: 75vh; //50vw;

        @media all and (max-width: $screen-phone-max-width) {
            height: 0;
            padding-bottom: 177%;
        }

        .darkener {
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            top: 0;
            left: 0;
            z-index: 3;
            height: 100%;
            width: 100%;
            opacity: 0.6;
        }

        .gradient {
            background: linear-gradient(180deg, #001517 0%, rgba(0, 21, 23, 0.0001) 51.39%, #001517 99.11%);
            opacity: 0.5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 3;
        }

        video {
            object-fit: cover;
        }

        .logo-with-text {
            display: flex;
            
            align-items: center;
            width: 1111px;
            margin: 30px auto;

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                width: 950px;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                width: 730px;
                // height: 4.81vw;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 86.66vw;
            }

            .text {
                font-family: ObjectSans;
                font-size: $desktop-font-size-4;
                line-height: 120%;
                text-transform: uppercase;
                color: #FFFFFF;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    font-size: 27px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: $phone-font-size-4;
                    width: 50%;
                }
            }
        }

        .logo {
            // display: none;
            margin-right: 34px;

            
            // top:
            background-image: url('/images/mht-logo.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 52px;
            height: 52px;           

            @media all and (min-width: $screen-desktop-min-width) and (max-width: $screen-desktop-max-width) {
                // width: 5.63vw;
                // height: 4.68vw;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                // width: 4.68vw;
                // height: 4.81vw;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 11vw;
                height: 11vw;
                margin-right: 6vw;
            }

            // @media all  and (max-width: $screen-tablet-max-width) {
            //     background-image: url('/images/mht-logo.svg');
            // }
        }

        .img {
            position: absolute;
            width: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: 1;
        }

        .header-logo {
            bottom: 0;
            height: 100%;

            video {
                height: 100%;
                width: 100%;
            }

            @media all and (min-width: $screen-desktop-min-width) and (max-width: $screen-desktop-max-width) {
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            }

            @media all and (max-width: $screen-phone-max-width) {
            }
        }
    }
</style>