<template>
    <section class="contacts">
        <div class="section-header">
            <h1 v-html="title"></h1>
        </div>
        <p class="pretext"></p>
        <div class="contacts-wrapper">
            <div class="contact" v-for="(contact, index) in items">
                <span class="type" v-html="contact.type"></span>
                <a :href="`mailto:${contact.email}`" v-html="contact.email"></a>
            </div>
        </div>
<!--        <a href="http://futurehistory.ru" target="_blank"> -->
<!--            <img class="fhlogo" src="~/static/images/fh-logo.gif" alt=""> -->
<!--        </a> -->
    </section>
</template>

<script>
    

    export default {
        name: "partners",
        components: {
        },
        data() {
            let basePath = 'components.contacts';
            let title = this.getLocalized(basePath, 'title');
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                title,
                items
            }
        }
    }
</script>

<style lang="scss">
    .contacts {
        margin-top: 150px !important;
        padding-bottom: 62px;
        position: relative;

        .pretext {
            font-size: $desktop-font-size-1;
            line-height: 140%;
            margin-top: 30px;
            margin-bottom: 20px;
            width: 50%;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-1;
                width: unset;
            }
        }

        .fhlogo {
            margin-top: 20px;
            cursor: pointer;
            width: 80%;
            max-width: 350px;
            margin-left: -80px;

            @media all and (max-width: $screen-phone-max-width) {
                 margin: 0 auto;
                 max-width: unset;
                 width: 90%;
            }
        }

        @include section-header-line (12px, -68%, 0, -41%, 0, -46%, 0, -47%, 127.4vw, 14.66vw);

        .contacts-wrapper {
            margin-top: 57px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                margin-top: 50px;
                // padding-left: 56px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: 17px;
            }
        }
    }

    .contact {
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;

        a, .type {
            display: block;
        }

        a {
            font-size: $desktop-font-size-5;
            margin-bottom: 40px;
            text-decoration-line: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-1;
                margin-bottom: 20px;
            }
        }

        .type {
            font-size: $desktop-font-size-1;
            font-weight: normal;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-1;
                margin-bottom: 5px;
            }
        }
    }
</style>