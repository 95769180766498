import { render, staticRenderFns } from "./instructions.vue?vue&type=template&id=a90c5488&"
import script from "./instructions.vue?vue&type=script&lang=js&"
export * from "./instructions.vue?vue&type=script&lang=js&"
import style0 from "./instructions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "instructions.vue"
export default component.exports