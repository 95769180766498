<template>
<!--    <section class="partners"> -->
<!--        <div class="section-header"> -->
<!--            <h1 v-html="title"></h1> -->
<!--        </div> -->
<!--        <div class="partners-wrapper"> -->
<!--            <div class="logos_block1"> -->
<!--              <div class="logos_block1_subblock1"> -->
<!--                <a href="https://plus.yandex.ru" target="_blank"><img class="logo logo_yandex" src="/images/yandex-plus.png"></a> -->
<!--              </div> -->
<!--            </div> -->
<!--            <p class="extraLogosSection">Информационные партнеры:</p> -->
<!--            <div class="logos_block2"> -->
<!--                <a href="https://www.cosmo.ru/" target="_blank"><img class="logo logo_cosmo" src="/images/cosmopolitan-white.svg"></a> -->
<!--                <a href="https://digitalreporter.ru/" target="_blank"><img class="logo logo_reporter" src="/images/digital-reporter.svg"></a> -->
<!--            </div> -->
<!--        </div> -->
<!--    </section> -->
</template>

<script>


    export default {
        name: "partners",
        components: {
        },
        data() {
            let basePath = 'components.partners';
            let title = this.getLocalized(basePath, 'title');
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                title,
                items
            }
        }
    }
</script>

<style lang="scss">

    .extraLogosSection {
        line-height: 1;
        margin-bottom: 32px;
        margin-top: 95px;
        font-size: 20px;
        line-height: 40px;

        @media screen and (max-width: $screen-tablet-max-width) {
          margin-top: 66px;
        }
    }

    .logos_block1, .logos_block2 {
      font-size: 20px;
      line-height: 40px;
    }

    .logos_block1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;

      @media screen and (max-width: $screen-tablet-max-width) {
        flex-direction: column;
        margin-top: 58px;
      }

      .logos_block1_subblock1 {
        display: flex;
        flex-direction: column;

        .logo_yandex {
            width: 328px;

            @media screen and (max-width: $screen-tablet-max-width) {
                width: 100%;
                max-width: 305px;
            }
        }

        .logo_discover {
            width: 225px;
            margin-top: 44px;

            @media screen and (max-width: $screen-tablet-max-width) {
                width: 100%;
                max-width: 211px;
                margin-top: 32px;
            }
        }



        @media screen and (max-width: $screen-tablet-max-width) {
          width: 100%;
        }

      }

      .logos_block1_subblock2 {
        margin-left: 264px;
        margin-top: -20px;

        @media screen and (max-width: $screen-tablet-max-width) {
          margin-left: inherit;
          width: 100%;
          margin-top: 48px;
        }

        .logo_russpass {
            width: 225px;

            @media screen and (max-width: $screen-tablet-max-width) {
                width: 80%;
                max-width: 211px;
            }
        }

        p {
          max-width: 361px;
          width: 100%;
          line-height: 200%;
          margin-bottom: 40px;
          margin-top: 10px;

          @media screen and (max-width: $screen-tablet-max-width) {
              margin-bottom: 30px;
          }
        }
      }
    }

    .logos_block2 {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 80px;

      @media screen and (max-width: $screen-tablet-max-width) {
        flex-wrap: wrap;
      }

      .logo_cosmo {
          width: 201px;

          @media screen and (max-width: $screen-tablet-max-width) {
              width: 151px;
              margin-right: 40px;
              margin-bottom: 20px;
          }
      }

      .logo_reporter {
          width: 156px;
          margin-left: 80px;

          @media screen and (max-width: $screen-tablet-max-width) {
              width: 117px;
              margin-left: 0px;
          }
      }
    }


    .partners {
        margin-top: 100px !important;
        position: relative;

        @include section-header-line (35px, -78%, 35px, -51%, 25px, -55%, 0, -47%, 127.2vw, 14.66vw);

        .partners-wrapper {
            margin-top: 66px;
            line-height: 123px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                margin-top: 66px;
                line-height: unset;
                height: unset;

                .partner {
                    margin-left: 56px;

                    img {
                        width: unset;
                    }
                }

                .partner:nth-of-type(odd) {
                    margin-bottom: 76px;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: 0 !important;
                height: unset;
                line-height: unset;

                .partner {
                   width: 50vw;
                   margin-left: auto;
                   margin-right: auto;
                }

                .partner:nth-of-type(odd) {
                    width: 75vw;
                    margin-left: auto;
                    margin-right: auto;
                }

            }
        }
    }

</style>
